import React from "react";
import MeinSlider from './Slider/MeinSlider';

class Sieben extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "Sieben",
      src: '../Archiv/sieben',
      images: [
        'Archiv/sieben/sieben.jpg',
        'Archiv/sieben/eins.jpg',
        'Archiv/sieben/zwei.jpg'
      ]
    }
  }
  componentDidMount() {
    this.props.enterGallery();
    this.props.sendTitle(this.state.title);
  }
  
  render() {
    return (
      <main className="text-center">
        <div className= "row">
            <div className="col main">
              <MeinSlider 
                sendPager={this.props.sendPager}
                images={this.state.images}
              />
            </div>
        </div>
      </main>
    )
  }
}
 
export default Sieben;