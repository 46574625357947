import React from "react";
import MeinSlider from './Slider/MeinSlider';

class Eternal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "The Eternal Sunshine of the Spotless Mind",
      src: '../Archiv/sunshine',
      images: [
        'Archiv/sunshine/theeternalsunshine.jpg',
        'Archiv/sunshine/theeternalsunshine2.jpg',
      ]
    }
  }
  componentDidMount() {
      this.props.enterGallery();
      this.props.sendTitle(this.state.title);
  }
  
  render() {
    return (
      <main className="text-center">
        <div className= "row">
            <div className="col main">
              <MeinSlider 
                sendPager={this.props.sendPager}
                images={this.state.images}
              />
            </div>
        </div>
      </main>
    )
  }
}
 
export default Eternal;