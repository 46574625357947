import React from "react";
import MeinSlider from './Slider/MeinSlider';
      
class Jamon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        title: "Jamón Ibérico",
        src: "../Archiv/jamon",
        images: [
            "Archiv/jamon/jamon_iberico_1.jpg",
            "Archiv/jamon/jamon_iberico_2.jpg",
            "Archiv/jamon/jamon_iberico_3.jpg",
            "Archiv/jamon/jamon_iberico_4.jpg",
            "Archiv/jamon/jamon_iberico_5.jpg",
            "Archiv/jamon/jamon_iberico_6.jpg",
            "Archiv/jamon/jamon_iberico_7.jpg",
            "Archiv/jamon/jamon_iberico_8.jpg",
            "Archiv/jamon/jamon_iberico_9.jpg",
            "Archiv/jamon/jamon_iberico_10.jpg",
            "Archiv/jamon/jamon_iberico_11.jpg",
            "Archiv/jamon/jamon_iberico_12.jpg",
            "Archiv/jamon/jamon_iberico_13.jpg",
            "Archiv/jamon/jamon_iberico_14.jpg",
            "Archiv/jamon/jamon_iberico_15.jpg",
            "Archiv/jamon/jamon_iberico_16.jpg"
        ],
    };
  }

  componentDidMount() {
    this.props.enterGallery();
    this.props.sendTitle(this.state.title);
  }
  
  render() {
    return (
      <main className="text-center">
        <div className= "row">
            <div className="col main">
            <MeinSlider 
                sendPager={this.props.sendPager}
                images={this.state.images}
              />
            </div>
        </div>
      </main>
    )
  }
}

export default Jamon;

