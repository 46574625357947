import React from "react";
      
class Title extends React.Component {
    render() {
        return (
            <ul className="navbar-nav">
                <li className="nav-item">
                    <div id="title" className="typo">{this.props.title}</div>
                </li>
            </ul>
        )
    }
}
 
export default Title;


