import React from "react";
import MeinSlider from './Slider/MeinSlider';
      
class Paradise extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "Paradise City",
      src: '../Archiv/paradise',
      images: [
        'Archiv/paradise/paradisecity1.jpg',
        'Archiv/paradise/paradisecity2.jpg',
        'Archiv/paradise/martha_kunstverein-w.png',
        'Archiv/paradise/paradisecity3.jpg',
        'Archiv/paradise/paradisecity4.jpg',
        'Archiv/paradise/martha_kunstverein_2-w.png',
      ]
    }
  }

  componentDidMount() {
    this.props.enterGallery();
    this.props.sendTitle(this.state.title);
  }
  
  render() {
    return (
      <main className="text-center">
        <div className= "row">
            <div className="col main">
              <MeinSlider 
                sendPager={this.props.sendPager}
                images={this.state.images}
              />
            </div>
        </div>
      </main>
    )
  }
}
 
export default Paradise;