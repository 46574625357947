import React, { Component } from "react";
 
import {
    Route,
    NavLink,
    HashRouter,
    Switch
  } from "react-router-dom";

import Home from "./Home/Home";
import Book from "./Book";
import Info from "./Info";
import Eclat from "./Eclat";
import Eternal from "./Eternal";
import Paradise from "./Paradise";
import Passiflora from "./Passiflora";
import Jamon from "./Jamon";
import Sieben from "./Sieben";

import Pager from "./Pager/Pager"
import Title from "./Title/Title"
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";
import Crux from "./Crux";
  
class Main extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            test: "test",
            gallery: false,
            projectTitle: '',
            pager: '',
            loading: "false"
        }
        this.enterGallery = this.enterGallery.bind(this)
        this.leaveGallery = this.leaveGallery.bind(this)
        this.getTitle = this.getTitle.bind(this)
        this.getPager = this.getPager.bind(this)
        this.load = this.load.bind(this)
    }

    enterGallery() {
        this.setState({gallery: true})
    }
    leaveGallery() {
        this.setState({gallery: false})
    }
    
    showPager() {
        if (this.state.gallery === true) {
            return <Pager pager={this.state.pager}/>
        }
    }
    showTitle() {
        return <Title title={this.state.projectTitle}/>
    }
    getTitle(title) {
        this.setState({projectTitle: title})
    }

    getPager(pagerNew) {
        this.setState({pager: pagerNew})
    }
    load(x) {
        this.setState({loading: x});
    }

    render() {
    if (this.state.loading === "false" ) {
        return (
            // hash router -> foundation of navigation and browser history handling
            <HashRouter>
                <div className="page container-fluid">
                    <header>
                        <nav className="navbar navbar-expand">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Switch>
                                        <Route
                                            path="/info"
                                        >
                                            <NavLink
                                                className="nav-link"
                                                id="friedel"
                                                exact
                                                to="/"
                                            >
                                                Home
                                            </NavLink>
                                        </Route>
                                        <Route
                                            path="/"
                                        >
                                            <NavLink
                                                className="nav-link"
                                                id="friedel"
                                                exact
                                                to="/"
                                            >
                                                Martha Frieda Friedel
                                            </NavLink>
                                        </Route>
                                    </Switch>
                                </li>
                            </ul>
                            <ul className="navbar-nav ml-auto">
                                {this.state.projectTitle !== "Jamón Ibérico" ? (
                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link"
                                            to="/book"
                                            id="book"
                                        >
                                            Book
                                        </NavLink>
                                    </li>
                                ) : (
                                    <li className="nav-item">
                                        <NavLink
                                            className="nav-link"
                                            to="/"
                                            id="projects"
                                        >
                                            Projects
                                        </NavLink>
                                    </li>
                                )}

                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/info"
                                        id="info"
                                    >
                                        Info
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://martha-friedel.tumblr.com/"
                                        target="_blank" rel="noopener noreferrer"
                                        className="nav-link"
                                        id="tumblr"
                                    >
                                        Diary
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </header>

                    <div className={"content " + this.state.projectTitle}>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={() => (
                                    <Home
                                        leaveGallery={this.leaveGallery}
                                        sendTitle={this.getTitle}
                                    />
                                )}
                            />
                            <Route
                                path="/book"
                                render={() => (
                                    <Book
                                        sendTitle={this.getTitle}
                                        enterGallery={this.enterGallery}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                            <Route
                                path="/info"
                                render={() => (
                                    <Info
                                        leaveGallery={this.leaveGallery}
                                        sendTitle={this.getTitle}
                                    />
                                )}
                            />
                            <Route
                                path="/impressum"
                                render={() => (
                                    <Impressum
                                        leaveGallery={this.leaveGallery}
                                        sendTitle={this.getTitle}
                                    />
                                )}
                            />
                            <Route
                                path="/datenschutz"
                                render={() => (
                                    <Datenschutz
                                        leaveGallery={this.leaveGallery}
                                        sendTitle={this.getTitle}
                                    />
                                )}
                            />
                            <Route
                                path="/eclat"
                                render={() => (
                                    <Eclat
                                        enterGallery={this.enterGallery}
                                        sendTitle={this.getTitle}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                            <Route
                                path="/sieben"
                                render={() => (
                                    <Sieben
                                        enterGallery={this.enterGallery}
                                        sendTitle={this.getTitle}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                            <Route
                                path="/eternal"
                                render={() => (
                                    <Eternal
                                        enterGallery={this.enterGallery}
                                        sendTitle={this.getTitle}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                            <Route
                                path="/paradise"
                                render={() => (
                                    <Paradise
                                        enterGallery={this.enterGallery}
                                        sendTitle={this.getTitle}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                            <Route
                                path="/passiflora"
                                render={() => (
                                    <Passiflora
                                        enterGallery={this.enterGallery}
                                        sendTitle={this.getTitle}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                            <Route
                                path="/jamon"
                                render={() => (
                                    <Jamon
                                        enterGallery={this.enterGallery}
                                        sendTitle={this.getTitle}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                            <Route
                                path="/crux"
                                render={() => (
                                    <Crux
                                        enterGallery={this.enterGallery}
                                        sendTitle={this.getTitle}
                                        sendPager={this.getPager}
                                    />
                                )}
                            />
                        </Switch>
                    </div>

                    <footer>
                        <nav className="navbar">
                            {this.showTitle()}
                            {this.showPager()}
                        </nav>
                    </footer>
                </div>
            </HashRouter>
        );  
    } else {
        return (
        <div>{this.state.loading}</div>
        )
    }
                  
    }
}
 
export default Main;