import {BsChevronCompactRight, BsChevronCompactLeft} from 'react-icons/bs';
import React from "react";
import Vimeo from '@u-wave/react-vimeo';

class MeinSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
        };
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    componentDidMount() {
        this.props.sendPager("1 / " + this.props.images.length);
    }

    next() {
        let x = this.state.currentImage + 1;
        if (x === this.props.images.length) {
            x = 0;
        }
        this.setState({currentImage: x});
        let y = x + 1;
        this.props.sendPager(y + " / " + this.props.images.length);
    }

    prev() {
        let x = this.state.currentImage - 1;
        if (x < 0) {
            x += this.props.images.length;
        }
        this.setState({currentImage: x});
        let y = x + 1;
        this.props.sendPager(y + " / " + this.props.images.length);
    }

    render() {
        if (this.props.images.length > 1) {

            const imagePath = this.props.images[this.state.currentImage];
            let element  = <></>;
            let isVideo = false;

            if (imagePath.substr(0, 5) === 'https') {
                isVideo = true;
                const pathParts = imagePath.split("_");

                const style = {
                    width: pathParts[1] + "%"
                }

                element = <div style={style}>
                    <Vimeo
                        video={pathParts[0]}
                        autoplay={true}
                        controls={false}
                        showByline={false}
                        responsive={true}
                    />
                </div>;
            } else {
                element = <img
                    className="sliderImages"
                    src={imagePath}
                    alt="slider"
                />;
            }

            return (
                <div className="slider d-flex justify-content-center">
                    {!isVideo ?
                        <div style={{position: 'relative'}}>
                            {element}
                            {imagePath.split(".")[0].substr(-2, 2) === '-w' ?
                                <span className={'copyright'}>© Nico Wefers</span> : <></>}
                        </div> : <>{element}</>
                    }
                    <div className="prev navi" onClick={this.prev}><BsChevronCompactLeft/></div>
                    <div className="next navi" onClick={this.next}><BsChevronCompactRight/></div>
                </div>
            );
        } else {
            return (
                <div className="slider d-flex justify-content-center">
                    <img
                        className="sliderImages"
                        src={this.props.images[this.state.currentImage]}
                        alt="slider"
                    />
                </div>
            );
        }
    }
}

export default MeinSlider;
