import React from "react";

import MeinSlider from "./Slider/MeinSlider";


export default class Book extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        title: "Jamón Ibérico",
        src: "../Archiv/books/jamon",
        images: [
            "Archiv/books/jamon/Jamon_Iberico_8.gif"
        ],
    };
  }
  componentDidMount() {
    this.props.enterGallery();
    this.props.sendTitle(this.state.title);
  }
  
  render() {
    return (
        <main className="text-center">
            <div className="row">
                <div className="col main">
                    <MeinSlider
                        sendPager={this.props.sendPager}
                        images={this.state.images}
                    />
                </div>
            </div>
        </main>
    );
  }
}