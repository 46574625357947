import React from "react";
import MeinSlider from './Slider/MeinSlider';
      
class Eclat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          title: "Éclat",
          src: '../Archiv/eclat',
          images: [
            'Archiv/eclat/eclat1.jpg',
            'Archiv/eclat/eclat2.jpg',
            'Archiv/eclat/eclat3.jpg',
            'Archiv/eclat/eclat4.jpg',
            'Archiv/eclat/eclat5.jpg',
            'Archiv/eclat/eclat6.jpg',
            'Archiv/eclat/eclat7.jpg',
            'Archiv/eclat/eclat8.jpg',
            'Archiv/eclat/eclat9.jpg',
            'Archiv/eclat/eclat10.jpg',
            'Archiv/eclat/eclat11.jpg',
            'Archiv/eclat/eclat12.jpg',
            'Archiv/eclat/eclat13.jpg',
            'Archiv/eclat/eclat14.jpg',
            'Archiv/eclat/eclat15.jpg',
            'Archiv/eclat/eclat16.jpg',
            'Archiv/eclat/eclat17.jpg',
            'Archiv/eclat/eclat18.jpg',
            'Archiv/eclat/eclat19.jpg',
            'Archiv/eclat/eclat20.jpg',
            'Archiv/eclat/eclat21-w.jpg',
          ]
        }
    }
    componentDidMount() {
        this.props.enterGallery();
        this.props.sendTitle(this.state.title);
    }
    
    render() {
        return (
            <main className="text-center">
                <div className= "row">
                    <div className="col main">
                        <MeinSlider
                            sendPager={this.props.sendPager}
                            images={this.state.images}
                        />
                    </div>
                </div>
            </main>
        )
    }
}
 
export default Eclat;