import React from "react";
      
class Pager extends React.Component {
    render() {
        return (
            <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                    <p className="typo">{this.props.pager}</p>
                </li>
            </ul>
        )
    }
}
 
export default Pager;



