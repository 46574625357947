import React from "react";

class Impressum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Impressum",
        };
    }
    componentDidMount() {
        this.props.leaveGallery();
        this.props.sendTitle(this.state.title);
    }

    render() {
        return (
            <div className="row">
                <div className="col-6">
                    <main className="cv px-4">
                        <p>Martha Friedel</p>
                        <p>Sophienstraße 32</p>
                        <p>34117 Kassel</p>
                        <p>E-Mail: friedel_martha@gmx.de</p>
                        <p>Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle über‐
                        nehmen wir keine Haftung für die Inhalte externer Links.
                        Für den Inhalt der verlinkten Seiten sind ausschließlich deren
                            Betreiber verantwortlich.</p>
                    </main>
                </div>
            </div>
        );
    }
}

export default Impressum;
