import React from "react";
import {NavLink} from "react-router-dom";

export default class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hover: false,
            background: "default",
            title: ""
        };
        this.setEclat = this.setEclat.bind(this);
        this.setSunshine = this.setSunshine.bind(this);
        this.setParadise = this.setParadise.bind(this);
        this.setPassiflora = this.setPassiflora.bind(this);
        this.setJamon = this.setJamon.bind(this);
        this.setSieben = this.setSieben.bind(this);
        this.setCrux = this.setCrux.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    setEclat() {
        this.setState({background: "eclat"})
    }

    setSunshine() {
        this.setState({background: "sunshine"})
    }

    setParadise() {
        this.setState({background: "paradise"})
    }

    setPassiflora() {
        this.setState({background: "passiflora"})
    }

    setJamon() {
        this.setState({background: "jamon"})
    }

    setSieben() {
        this.setState({background: "sieben"})
    }

    setCrux() {
        this.setState({background: "wurst"})
    }

    mouseLeave() {
        this.setState({background: "default"})
    }

    componentDidMount() {
        this.props.leaveGallery();
        this.props.sendTitle(this.state.title);
    }

    render() {
        return (
            <main className={this.state.background} id="content">
                <ul className="top">
                    <li>
                        <NavLink
                            id="blue"
                            to="/eclat"
                            onMouseEnter={this.setEclat}
                            onMouseLeave={this.mouseLeave}
                        >
                            Éclat
                        </NavLink>
                    </li>
                    <li>
                        <p className="bull">·</p>
                    </li>
                    <li>
                        <NavLink
                            id="yellow"
                            to="/passiflora"
                            onMouseEnter={this.setPassiflora}
                            onMouseLeave={this.mouseLeave}
                        >
                            Passiflora
                        </NavLink>
                    </li>
                    <li>
                        <p className="bull">·</p>
                    </li>
                    <li>
                        <NavLink
                            id="green"
                            to="/eternal"
                            onMouseEnter={this.setSunshine}
                            onMouseLeave={this.mouseLeave}
                        >
                            The Eternal Sunshine of the Spotless Mind
                        </NavLink>
                    </li>
                </ul>
                <ul className="top">
                    <li>
                        <NavLink
                            id="pink"
                            to="/paradise"
                            onMouseEnter={this.setParadise}
                            onMouseLeave={this.mouseLeave}
                        >
                            Paradise City
                        </NavLink>
                    </li>
                    <li>
                        <p className="bull">·</p>
                    </li>
                    <li>
                        <NavLink
                            id="red"
                            to="/jamon"
                            onMouseEnter={this.setJamon}
                            onMouseLeave={this.mouseLeave}
                        >
                            Jamón Ibérico
                        </NavLink>
                    </li>
                    <li>
                        <p className="bull">·</p>
                    </li>
                    <li>
                        <NavLink
                            id="blau"
                            to="/crux"
                            onMouseEnter={this.setCrux}
                            onMouseLeave={this.mouseLeave}
                        >
                            die crux mit dir
                        </NavLink>
                    </li>
                </ul>
            </main>
        );
    }
}
 
