import React from "react";
import MeinSlider from './Slider/MeinSlider';

class Crux extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "Crux",
            src: '../Archiv/crux',
            images: [
                'https://vimeo.com/560976783_20',
                'Archiv/crux/wurst.jpg',
                'Archiv/crux/Smile_Haus.jpg',
                'Archiv/crux/manuel.jpg',
                'Archiv/crux/installation1.jpg',
                'https://vimeo.com/561216110_65',
                'Archiv/crux/Sonnenunterga.jpg',
                'Archiv/crux/Tyler-Druck.png',
                // 'Archiv/crux/installation.jpg'
            ]
        }
    }

    componentDidMount() {
        this.props.enterGallery();
        this.props.sendTitle(this.state.title);
    }

    render() {
        return (
            <main className="text-center">
                <div className="row">
                    <div className="col main">
                        <MeinSlider
                            sendPager={this.props.sendPager}
                            images={this.state.images}
                        />
                    </div>
                </div>
            </main>
        )
    }
}

export default Crux;