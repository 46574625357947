import React from "react";
import {NavLink} from "react-router-dom";

class Updates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
        };
    }
    componentDidMount() {
        this.props.leaveGallery();
        this.props.sendTitle(this.state.title);
    }

    mailtoOnClick() {
        let mailPart1 = 'friedel_martha';
        let mailPart2 = String.fromCharCode(Math.pow(2,6));
        let mailPart3 = 'gmx.de'
        window.location = "mailto:" + mailPart1 + mailPart2 + mailPart3;
    }

    render() {
        return (
            <div className="row">
                <div className="col">
                    <main className="cv px-4">
                        <table>
                            <tbody>

                            <tr>
                                <td>Martha Frieda Friedel</td>
                                <td className="info-content-column">Borna (b Leipzig), 07 - 03 - 1989</td>
                                <td className="text-right mail-td" onClick={this.mailtoOnClick}>E-Mail</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <td rowSpan="3" valign="top">education</td>
                                <td className="info-content-column">Staatliche Akademie der Bildenden Künste Stuttgart</td>
                                <td className="text-right info-year-column">2019</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Escuela de Arte y Superior de Diseño de Valencia</td>
                                <td className="text-right info-year-column">2015</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">MA of Finearts, Kunsthochschule Kassel at Bernhard Prinz and Florian Slotawa</td>
                                <td className="text-right info-year-column">2011 – 2018</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <td rowSpan="19" valign="top">group exhibitions</td>
                                <td className="info-content-column">PEP New Talents , Kommunale Galerie Berlin</td>
                                <td className="text-right info-year-column" rowSpan="1" valign="top">2022</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Send me an Image, C/O Berlin</td>
                                <td className="text-right info-year-column" rowSpan="2" valign="top">2021</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Kasseler Kunstpreis 2021, Kunstverein Kassel</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Organ Vida - Hesitant Images, Museum of Contemporary Art Zagreb, Croatia</td>
                                <td className="text-right info-year-column" rowSpan="1" valign="top">2020</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Portraits – Hellerau Photography Award, Dresden</td>
                                <td className="text-right info-year-column" rowSpan="4" valign="top">2019</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Poznan Photo Diplom Award, Poznan, Poland</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Festival Les Boutographies , Montpellier , France</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Views of documenta 14., Tokonoma, Kassel</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Wenn Gott mal nicht aufpasst, Triennale der Photographie Hamburg, Tempel 1884</td>
                                <td className="text-right info-year-column" rowSpan="2" valign="top">2018</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Graduation Exhibition 2018 - Documentahalle Kassel</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">What i‘ve learned, Martha Friedel & Max Hänisch, Fenster9 Kassel</td>
                                <td className="text-right info-year-column" rowSpan="2" valign="top">2017</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">NONEWFRIENDS, Fullepavillon, Kassel</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Was kann Selbsthilfe, Humboldt Carre, Berlin</td>
                                <td className="text-right info-year-column" rowSpan="1" valign="top">2016</td>
                            </tr>

                            <tr>
                                <td>Caso/ Fall, f/stop Festival, Alte Baumwollspinnerei Leipzig</td>
                                <td className="text-right info-year-column" rowSpan="4" valign="top">2014</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Grampus, Schute, Hamburg</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Domestic Scape, Europäischer Monat der Fotografie, MeinBlau, Berlin</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Deutscher Fotojugendpreis, Photokina, Köln</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Caso/ Fall, Interim, Kassel</td>
                                <td className="text-right info-year-column">2013</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Yes this year it‘s Documenta, Kreuzbergpavillon, Berlin</td>
                                <td className="text-right info-year-column">2012</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <td rowSpan="3" valign="top">solo exhibitions</td>
                                <td className="info-content-column">die crux mit dir, Kunsthalle Willingshausen</td>
                                <td className="text-right info-year-column">2021</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Éclat- Die Ernennung des Schönen , Südflügel Kulturbahnhof Kassel</td>
                                <td className="text-right info-year-column">2018</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Jamon Iberico, AltaSeis, Valencia</td>
                                <td className="text-right info-year-column" valign="top">2015</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <td rowSpan="8" valign="top">prices & grants</td>
                                <td className="info-content-column">Kasseler Kunstpreis</td>
                                <td className="text-right info-year-column">2021</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">work grant in Willingshausen</td>
                                <td className="text-right info-year-column">2020</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Portraits – Hellerau Photography Award, Dresden (finalist)</td>
                                <td className="text-right info-year-column" rowSpan="2" valign="top">2019</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Poznan Photo Diplom Award</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Otto- Braun- Fonds</td>
                                <td className="text-right info-year-column">2018</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">vdek-price</td>
                                <td className="text-right info-year-column" rowSpan="2" valign="top">2016</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">SHOSTA travel grant</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Deutscher Fotojugendpreis</td>
                                <td className="text-right info-year-column">2014</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <td rowSpan="7" valign="top">publications</td>
                                <td className="info-content-column">Catalog PORTRAITS – HELLERAU PHOTOGRAPHY AWARD</td>
                                <td rowSpan="5" className="text-right info-year-column" valign="top">2019</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Catalog Poznan Photo Diploma Award</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Catalog of Photofestival Boutographies Montpellier</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Coverfoto „Der Greif “ Issue 12</td>
                            </tr>
                            <tr>
                                <td className="info-content-column">Bauhaus/ Documenta, Views of documenta 14, Spector Books</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Photonews Nr. 11/18</td>
                                <td className="text-right info-year-column">2018</td>
                            </tr>

                            <tr>
                                <td className="info-content-column">Jamon Iberico, self-publishing</td>
                                <td className="text-right info-year-column">2015</td>
                            </tr>

                            <tr>
                                <td>&nbsp;</td>
                            </tr>

                            <tr>
                                <td><NavLink id="green" className="infolinks" to="/Datenschutz">Datenschutz</NavLink></td>
                                <td><NavLink id="blue" className="infolinks" to="/impressum">Impressum</NavLink></td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </main>
                </div>
            </div>
        );
    }
}

export default Updates;
